<template>
    <b-modal id="VisualRobotModal" size="xl" aria-label="VisualRobot" scrollable hide-header hide-footer>
        <div class="modal-header border-0"><button class="btn-close" type="button" block @click="$bvModal.hide('VisualRobotModal')"></button></div>
        <div class="modal-body pb-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <!-- Portfolio Modal - Title-->
                        <h2 class="modal-title mb-0 text-center">Visual Guidance Robot</h2>
                        <!-- Icon Divider-->
                        <DividerIcon/>
                        <!-- Portfolio Modal - Image-->
                        <img class="img-fluid rounded mb-5" src="@/assets/PortfolioModals/VisualRobot/Cover.png" alt="Visual Guidance Robot Looking" />
                        <!-- Portfolio Modal - Text-->
                        <h3>Introduction</h3>
                        <p>This project is for my thesis "Design of Visual System for Intelligent Delivery Robot" in Intelligent Automobile Innovation Lab, Chongqing University, China, where I worked as an undergraduate research assistant. The whole system consists of three parts: Car, Sercer and Controller. The car was based on Robot Operation System, on Linux. The server was built on Linux. And the Controller was designed on Windows. All codes are written in C++.</p>
                        <p>Two main tasks are finished in this project.</p>
                        <ul>
                            <li>Built a real-time “Car (Linux)-Server (Linux)-Controller (Windows)” vision-based monitor and control system with TCP/IP protocal and h.264 compression standard, limited system delay within 200ms under 4G network.</li>
                            <li>Created a vision-based road detection algorithm and implemented with OpenCV, programed the car on Robot Operation System and enabled it to fully auto-run in three types of fields.</li>
                        </ul>
                        <hr>

                        <h3>Details</h3>
                        <p>As one of the most important ways for human to perceive the world, vision plays an irreplaceable role in the driving process of human drivers. Machine vision based on video signal also provides indispensable driving decision-making basis and intuitive monitoring data for assistant driving technology and unmanned driving technology. According to the application scenario of intelligent delivery robot, this project focuses on developing a complete and independent visual system for intelligent distribution robot, including road detection technology with good robustness and real-time performance, low-delay multi-data transmission technology, remote monitoring and human rapid takeover technology, real-time object detection and location technology.</p>
                        <h4>Road Detection</h4>
                        <p>Firstly, aiming at road detection, in this paper, an improved algorithm is created based on the seed region growth algorithm, which greatly enhances the stability of the segmentation method. The image is processed separately in BGR color space and HSV color space, then the two segmentation results are fused and multi-frame filtered. The experimental results show that the proposed road segmentation scheme has high tolerance to illumination changes, and its robustness and real-time performance meet the requirements of guiding low speed vehicles.</p>
                        <figure class="figure w-100">
                            <img class="figure-image img-fluid" src="@/assets/PortfolioModals/VisualRobot/RoadDetection.png" alt="Road Detection Algorithm"/>
                            <figcaption class="figure-caption text-center">Road Detection Algorithm Performance</figcaption>
                        </figure>
                        <h4>Vision-based Monitor and Control System</h4>
                        <p>For automatic driving vehicles without manned capacity, this project designs a long-distance, low-delay multi-data transmission system based on general hardware platform, which consists of vehicle, server and background computer. Administrators can take control of the vehicle at any time on the background computer to ensure vehicle operation safety. Using TCP protocol and JPEG/H.264 coding, this paper designs two image transmission modes under LAN and Internet respectively to meet the requirements of different environments. Based on this system, the background end uses Yolo v3 neural network to realize real-time detection of people, vehicles and other objects on the road.</p>
                        <figure class="figure features-img" style="display:block; margin-left:auto; margin-right:auto;">
                            <img class="figure-image img-fluid" src="@/assets/PortfolioModals/VisualRobot/MonitorSystem.png" alt="Monitor and Control System"/>
                            <figcaption class="figure-caption text-center">Monitor and Control System Architecture</figcaption>
                        </figure>
                        <p>The user interface for human supervisors is like:</p>
                        <figure class="figure features-img" style="display:block; margin-left:auto; margin-right:auto;">
                            <img class="figure-image img-fluid" src="@/assets/PortfolioModals/VisualRobot/HumanControlMode.png" alt="Human Control Mode"/>
                            <figcaption class="figure-caption text-center">Human Control Mode</figcaption>
                        </figure>
                        <figure class="figure features-img" style="display:block; margin-left:auto; margin-right:auto;">
                            <img class="figure-image img-fluid" src="@/assets/PortfolioModals/VisualRobot/AutoDrivingMode.png" alt="Auto Driving Mode"/>
                            <figcaption class="figure-caption text-center">Auto Driving Mode</figcaption>
                        </figure>
                        <hr>

                        <h3>Display</h3>
                        <p>The following video clip was captured during the test in the campus of Chongqing University.</p>
                        <p align="center"><img class="features-img" src="@/assets/PortfolioModals/VisualRobot/VisualRobotTest.gif" alt="Visual Robot Test"/></p>
                        <hr>

                        <h3>Repository</h3>
                        <p class="mb-4">
                            <a class="text-black" href="https://github.com/LiGaCu/Visual_System_for_Intelligent_Delivery_Robot" target="_blank" rel="noopener noreferrer">
                                <b-icon icon="github" font-scale="2"/>
                            </a>
                        </p>
                        <!--Footer Close Botton-->
                        <div class="text-center">
                            <button class="btn btn-footer" href="#!" block @click="$bvModal.hide('VisualRobotModal')">
                                <b-icon icon="x"></b-icon>
                                Close Window
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import DividerIcon from '../DividerIcon.vue';

export default {
  name: 'VisualRobotModal',
  components: {
      DividerIcon
  }
}
</script>

<style scoped>
.btn-close {
    color: #1abc9c;
    font-size: 2rem;
    padding: 1rem;
}
.btn-footer {
    border-color: #1abc9c;
    background: #1abc9c;
    border-radius: 0.5rem;
    color: #fff;
}
.modal-title {
  font-size: 2.25rem;
  line-height: 2rem;
  color: #2c3e50;
}
.features-img {
    width: 90%;
}
@media (min-width: 992px) {
  .modal-title {
    font-size: 3rem;
    line-height: 2.5rem;
  }
}
</style>