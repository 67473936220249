<template>
    <b-modal id="MusicPlatformModal" size="xl" aria-label="Music Platform" scrollable hide-header hide-footer>
        <div class="modal-header border-0"><button class="btn-close" type="button" block @click="$bvModal.hide('MusicPlatformModal')"></button></div>
        <div class="modal-body pb-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <!-- Portfolio Modal - Title-->
                        <h2 class="modal-title mb-0 text-center">Music Platform</h2>
                        <!-- Icon Divider-->
                        <DividerIcon/>
                        <!-- Portfolio Modal - Image-->
                        <img class="img-fluid rounded mb-5" src="@/assets/PortfolioModals/MusicPlatform/Cover.jpeg" alt="Music Platform Home Page" />
                        <!-- Portfolio Modal - Text-->
                        <h3>Introduction</h3>
                        <p>In this project, I built a platform for music lovers to rank, comment, and listen to the music they love. Users can search for artist, album or track through its name. On the music profile page, users can see the detailed information, share their own ratings about lyric, melody, singer or mv as well as share their reviews.</p>
                        <h4>Links to my application (no longer available)</h4>
                        <p>Stable version can be found at:<br>http://ee599musics-env.eba-xmjfgjfi.us-west-1.elasticbeanstalk.com<br>More features version can be found at:<br>http://ee599music-env.eba-xycuv9fu.us-east-2.elasticbeanstalk.com</p>
                        <h4>Video</h4>
                        <p>The demonstration video of this project can be seen on <a href="https://www.youtube.com/watch?v=Yb-KuKEXqoo	&#38;t=40s">YouTube</a>.</p>
                        <hr>

                        <h3>Details</h3>
                        <p>There are four pages in this web app: Home, Artist, Album and Track. The search bar in Home page takes you to any one of the album, artist or track details page. Cover images of albums takes you to the corresponding album page. Cover images of tracks takes you to the corresponding track page.</p>
                        <figure class="figure features-img" style="display:block; margin-left:auto; margin-right:auto;">
                            <img class="figure-image img-fluid" src="@/assets/PortfolioModals/MusicPlatform/RoutingLogics.png" alt="Routing Logics"/>
                            <figcaption class="figure-caption text-center">Music Platform Routing Logics</figcaption>
                        </figure>
                        <p>The artist details page looks like the followings, sharing the same layout as the album details page.</p>
                        <p align="center">
                            <img class="features-img" src="@/assets/PortfolioModals/MusicPlatform/ArtistPage1.png" alt="ArtistPage One"/>
                            <img class="features-img" src="@/assets/PortfolioModals/MusicPlatform/ArtistPage2.png" alt="ArtistPage Two"/>
                        </p>
                        <p>The track details page looks like the followings.</p>
                        <p align="center">
                            <img class="features-img" src="@/assets/PortfolioModals/MusicPlatform/TrackPage1.png" alt="TrackPage One"/>
                            <img class="features-img" src="@/assets/PortfolioModals/MusicPlatform/TrackPage2.png" alt="TrackPage Two"/>
                        </p>
                        <hr>

                        <h3>Deployment</h3>
                        <p>Please pre-install Node.js in both Backend Server and Frontend Server to deploy our product.<br>Create your own database and import the schema.sql under backend_jiatong folder to your database.<br>Log in your backend instance and move the backend_jiatong folder to the backend instance.<br>Change the information in mysql.json under backend_jiatong folder according to your setting and do:</p>
<pre><code class="lang-shell">cd backend_jiatong
npm install
chmod start_server.sh
./start_server.sh
</code></pre>
                        <p>Then, you backend server should work.<br>To deploy the frontend program, log in to your frontend instance do the following:</p>
<pre><code class="lang-shell">cd deployBeanstalk
npm install
node server.js
</code></pre>
                        <p>Or, you can simply upload the deployBeanstalk.zip to AWS Beanstalk platform.<br>Then, you backend server should work.<br>(To change the data source to your own backend, your need to change the URL-prefix in the frontend-jiatong's Angular Project's services and rebuild the pages.)</p>
                        <figure class="figure features-img" style="display:block; margin-left:auto; margin-right:auto;">
                            <img class="figure-image img-fluid" src="@/assets/PortfolioModals/MusicPlatform/CloudDeployment.png" alt="Cloud Deployment"/>
                            <figcaption class="figure-caption text-center">This project was completely deployed on AWS</figcaption>
                        </figure>
                        <hr>

                        <h3>Repository</h3>
                        <p class="mb-4">
                            <a class="text-black" href="https://github.com/LiGaCu/MusicPlatform" target="_blank" rel="noopener noreferrer">
                                <b-icon icon="github" font-scale="2"/>
                            </a>
                        </p>
                        <!--Footer Close Botton-->
                        <div class="text-center">
                            <button class="btn btn-footer" href="#!" block @click="$bvModal.hide('MusicPlatformModal')">
                                <b-icon icon="x"></b-icon>
                                Close Window
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import DividerIcon from '../DividerIcon.vue';

export default {
  name: 'MusicPlatformModal',
  components: {
      DividerIcon
  }
}
</script>

<style scoped>
.btn-close {
    color: #1abc9c;
    font-size: 2rem;
    padding: 1rem;
}
.btn-footer {
    border-color: #1abc9c;
    background: #1abc9c;
    border-radius: 0.5rem;
    color: #fff;
}
.modal-title {
  font-size: 2.25rem;
  line-height: 2rem;
  color: #2c3e50;
}
.features-img {
    width: 90%;
}
@media (min-width: 992px) {
  .modal-title {
    font-size: 3rem;
    line-height: 2.5rem;
  }
}
</style>