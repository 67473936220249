<template>
    <b-modal id="TrojanFilmsModal" size="xl" aria-label="TrojanFilms" scrollable hide-header hide-footer>
        <div class="modal-header border-0"><button class="btn-close" type="button" block @click="$bvModal.hide('TrojanFilmsModal')"></button></div>
        <div class="modal-body pb-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <!-- Portfolio Modal - Title-->
                        <h2 class="modal-title mb-0 text-center">Trojan Films</h2>
                        <!-- Icon Divider-->
                        <DividerIcon/>
                        <!-- Portfolio Modal - Image-->
                        <img class="img-fluid rounded mb-5" src="@/assets/PortfolioModals/TrojanFilms01.jpeg" alt="TrojanFilms Page looking" />
                        <!-- Portfolio Modal - Text-->
                        <h3>Website</h3>
                        <p>You can try it out first: <br><a href="https://lijiatong1997.com/TrojanFilms">https://lijiatong1997.com/TrojanFilms</a></p>
                        <h3>Introduction</h3>
                        <p>Trojan Film a web application with user friendly layout that enables users to search for movies and TV series, browse details, watch trailers, share on social media, know about cast and crew, get recommended videos and so on. The original data for Trojan Film is from TMDB.</p>
                        <p>This project was developed with Angular and Bootstrap for frontend and Express.js for backend, hosted on Google Cloud Platform. During the development and tests, API server and web page server were fulfilled together by the same server running on GCP APP Engine. However, they are designed to operating separately (like right now, web pages are served by GitHub Pages and backend server are running on AWS). You can try it out locally on your machine by:</p>
                        <ol>
                            <li>Starting the backend server
                                <ul>
                                    <li>set your TMDB API key as an environment variable <code>TMDBAPI</code> (if you don&#39;t have one, it is free to register one)</li>
                                    <li><code>npm install</code> in the backend dictionary</li>
                                    <li><code>npm start</code></li>
                                </ul>
                            </li>
                            <li>Serving the frontend pages using Angular
                                <ul>
                                    <li><code>npm install</code> in the frontend dictionary</li>
                                    <li>changing the variable <code>serverURL</code> in <code>frontend/src/app/services/gethomedata.service.ts</code> file to <code>http://localhost:8080</code> </li>
                                    <li><code>ng serve</code>. </li>
                                </ul>
                            </li>
                        </ol>
                        <h3>Video</h3>
                        <p>The demonstration video of this project can be seen on <a href="https://www.youtube.com/watch?v=l58NvfWbW7c">YouTube</a>.</p>
                        <h3>Repository</h3>
                        <p class="mb-4">
                            <a class="text-black" href="https://github.com/LiGaCu/TrojanFilms" target="_blank" rel="noopener noreferrer">
                                <b-icon icon="github" font-scale="2"/>
                            </a>
                        </p>
                        <!--Footer Close Botton-->
                        <div class="text-center">
                            <button class="btn btn-footer" href="#!" block @click="$bvModal.hide('TrojanFilmsModal')">
                                <b-icon icon="x"></b-icon>
                                Close Window
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import DividerIcon from '../DividerIcon.vue';

export default {
  name: 'TrojanFilmsModal',
  components: {
      DividerIcon
  }
}
</script>

<style scoped>
.btn-close {
    color: #1abc9c;
    font-size: 2rem;
    padding: 1rem;
}
.btn-footer {
    border-color: #1abc9c;
    background: #1abc9c;
    border-radius: 0.5rem;
    color: #fff;
}
.modal-title {
  font-size: 2.25rem;
  line-height: 2rem;
  color: #2c3e50;
}
@media (min-width: 992px) {
  .modal-title {
    font-size: 3rem;
    line-height: 2.5rem;
  }
}
</style>