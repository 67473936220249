<template>
    <b-modal id="MoreProjectsModal" size="xl" aria-label="MoreProjects" scrollable hide-header hide-footer>
        <div class="modal-header border-0"><button class="btn-close" type="button" block @click="$bvModal.hide('MoreProjectsModal')"></button></div>
        <div class="modal-body pb-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <!-- Portfolio Modal - Title-->
                        <h2 class="modal-title mb-0 text-center">More Projects</h2>
                        <!-- Icon Divider-->
                        <DividerIcon/>
                        <!-- Portfolio Modal - Text-->
                        <h3>Parallel And Distributed File Processing</h3>
                        <h4>Nov 2021</h4>
                        <p align="center"><img class="img-fluid rounded mb-5" src="@/assets/PortfolioModals/MoreProjects/Project1-1.png" alt="Socket Programming" /></p>
                        <h4>Introduction</h4>
                        <p>A parallel and distributed file processing system is designed in this project. Histogram equalization (Example: <a href="https://lijiatong1997.com/ParallelAndDistributedFileProcessing/">https://lijiatong1997.com/ParallelAndDistributedFileProcessing/</a>) is used as an example task for this project. Actually, this system can be extended to other file processing tasks as well.</p>
                        <h4>System Architecture</h4>
                        <p>The whole system consists of three major components: front end page, central server that is running on AWS and compute nodes that are responsible for actual computation. Once a user visited the website and choose an image to upload, the front end page would send the file to the central server using HTTP post request. And the central server would either dispatch the task to a compute node immediately if there is an availability, or enqueue the task waiting for one compute node. The compute nodes would actively and regularly communicate with the central server once they are online, using Http get request. If there is a task pending processing, the compute node would get it and do the computation locally. The computation part is done by pre-compiled C++ program, either using OpenMP or CUDA to speed up for our project.</p>
                        <p>On the node side, both GPU and CPU version of computing is implemented in this project. The Python script would call the pre-compiled C++ program to finish the actual computation. On the device enabled CUDA, operators can use GPU to leverage parallel computing benefit.</p>
                        <p align="center"><img class="img-fluid" src="@/assets/PortfolioModals/MoreProjects/Project1-2.png" alt="Socket Programming" /></p>
                        <h4>Scalability and Stability</h4>
                        <p>In this system, multiple compute nodes can connect to the central server concurrently. The system harvests the computing power from all the nodes. There is a task queue in the central server. If the queue gets too long, we can connect more compute nodes to the system at any time without rebooting the central server. On the other side, the system has automatic recovery ability, meaning even if a compute node crashed during the processing, the central server would automatically resend the task once the node is back online, so that the client query will not loss.</p>
                        <h4>Repository</h4>
                        <p>
                            <a class="text-black" href="https://github.com/LiGaCu/ParallelAndDistributedFileProcessing" target="_blank" rel="noopener noreferrer">
                                <b-icon icon="github" font-scale="2"/>
                            </a>
                        </p>
                        <hr class="my-4">

                        <h3>Distributed System to Calculate the Shortest Path</h3>
                        <h4>Apr 2020 - June 2020</h4>
                        <img class="img-fluid rounded mb-5" src="@/assets/PortfolioModals/MoreProjects/Project2-1.jpg" alt="Socket Programming" />
                        <h4>Work description</h4>
                        <p>In this project, I build a distributed system to calculate the shortest path and its length between two vertexes that the user gives.</p>
                        <p>The user uses client to type in requested information (map id, source vertex, destination vertex, filesize), and client sends it to aws. Then aws gives the requested information to serverA and serverB, who will search the map and vertex information in their separated data files. After serverA and serverB finish searching and give back map information or no found massage to aws, aws forward the map information and requested information to serverC or no found massage to client. After serverC receives the map information and requested information, it uses Dijkstra Algorithm to find the shortest path and calculates delays. Then serverC sends the results to aws, who will then forward them to client.</p>
                        <p>Two main tasks are finished in this project.</p>
                        <ul>
                            <li>Built client, main server, map storing servers and calculating server structure to answer clients’ request and finished path finding with Dijkstra Algorithm.</li>
                            <li>Employed socket programming on Linux to transmit messages among five servers via TCP &amp; UD</li>
                        </ul>
                        <hr>

                        <h3>Other projects</h3>
                        <p>More projects are waiting for typesetting.</p>
                        <!--Footer Close Botton-->
                        <div class="text-center mt-4">
                            <button class="btn btn-footer" href="#!" block @click="$bvModal.hide('MoreProjectsModal')">
                                <b-icon icon="x"></b-icon>
                                Close Window
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import DividerIcon from '../DividerIcon.vue';

export default {
  name: 'MoreProjectsModal',
  components: {
      DividerIcon
  }
}
</script>

<style scoped>
.btn-close {
    color: #1abc9c;
    font-size: 2rem;
    padding: 1rem;
}
.btn-footer {
    border-color: #1abc9c;
    background: #1abc9c;
    border-radius: 0.5rem;
    color: #fff;
}
.modal-title {
  font-size: 2.25rem;
  line-height: 2rem;
  color: #2c3e50;
}
@media (min-width: 992px) {
  .modal-title {
    font-size: 3rem;
    line-height: 2.5rem;
  }
}
</style>