<template>
    <footer class="footer py-4">
        <div class="container">
            <div class="row align-items-center text-center">
                <div class="col-lg-6 text-lg-start">Copyright &copy; 2021 - 2022 Jiatong Li</div>
                <div class="col-lg-6 my-3 my-lg-0 text-lg-end">
                    <a class="btn btn-outline-light btn-social mx-2" href="mailto:lijiatong1997@outlook.com"><b-icon icon="envelope-fill"/></a>
                    <a class="btn btn-outline-light btn-social mx-2" href="https://www.linkedin.com/in/jiatong-li"><b-icon icon="linkedin"/></a>
                    <a class="btn btn-outline-light btn-social mx-2" href="https://github.com/ligacu"><b-icon icon="github"/></a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'MyFooter',
  components: {
  }
}
</script>

<style scoped>
.footer {
    font-size: 0.9rem;
    background-color: #2c3e50;
    color: white;
}

.btn-social {
    height: 2.5rem;
    width: 2.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 100%;
}
</style>