<template>
     <b-navbar toggleable class="navbar-expand-lg fixed-top" id="mainNav">
         <div class="container">
            <b-navbar-brand href="#app">Jiatong Li</b-navbar-brand>
            <b-navbar-toggle class="navbar-toggler text-uppercase font-weight-bold text-white" target="navbarResponsive">
                <template #default="{ expanded }">
                    Menu
                  <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
                  <b-icon v-else icon="chevron-bar-down"></b-icon>
                </template>
            </b-navbar-toggle>
            <b-collapse id="navbarResponsive" is-nav>
                <b-navbar-nav class="ms-auto" v-b-scrollspy="72">
                    <b-nav-item class="mx-0 mx-lg-1 text-uppercase" href="#portfolio">Portfolio</b-nav-item>
                    <b-nav-item class="mx-0 mx-lg-1 text-uppercase" href="#experience">Experience</b-nav-item>
                    <b-nav-item class="mx-0 mx-lg-1 text-uppercase" href="#about">About</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
         </div>
    </b-navbar>
</template>

<script>
export default {
  name: 'Navbar',
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      // Any code to be executed when the window is scrolled
      const navbarCollapsible = document.body.querySelector('#mainNav');
      if (!navbarCollapsible) {
          return;
      }
      if (window.scrollY === 0) {
          navbarCollapsible.classList.remove('navbar-shrink')
      } else {
          navbarCollapsible.classList.add('navbar-shrink')
          //console.log(navbarCollapsible.classList);
      }
    }
  }
}
</script>

<style scoped>
#mainNav {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  background: #2c3e50;
}
#mainNav .navbar-brand {
  color: #fff;
  font-family: 'Kaushan Script', cursive;
}
#mainNav .navbar-nav {
  margin-top: 1rem;
}
#mainNav .navbar-nav li.nav-item a.nav-link {
  color: #fff;
  padding: 1rem 0;
  border-radius: 0.5rem;
}
#mainNav .navbar-nav li.nav-item a.nav-link:hover {
  color: #1abc9c;
}
#mainNav .navbar-nav li.nav-item a.nav-link:active, #mainNav .navbar-nav li.nav-item a.nav-link:focus {
  color: #fff;
}
#mainNav .navbar-nav li.nav-item a.nav-link.active {
  color: #1abc9c;
}
#mainNav .navbar-toggler {
  font-size: 80%;
  padding: 0.8rem;
  background: #1abc9c;
  border-radius: 0.5rem;
}

@media (min-width: 992px) {
  #mainNav {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    transition: padding-top 0.3s, padding-bottom 0.3s;
  }
  #mainNav .navbar-brand {
    font-size: 1.75em;
    transition: font-size 0.3s;
  }
  #mainNav .navbar-nav {
    margin-top: 0;
  }
  #mainNav .navbar-nav > li.nav-item > a.nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  #mainNav .navbar-nav > li.nav-item > a.nav-link.active {
    color: #fff;
    background: #1abc9c;
  }
  #mainNav .navbar-nav > li.nav-item > a.nav-link.active:active, #mainNav .navbar-nav > li.nav-item > a.nav-link.active:focus, #mainNav .navbar-nav > li.nav-item > a.nav-link.active:hover {
    color: #fff;
    background: #1abc9c;
  }

  #mainNav.navbar-shrink {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  #mainNav.navbar-shrink .navbar-brand {
    font-size: 1.5em;
  }
}
</style>