<template>
    <b-modal id="SmartCarModal" size="xl" aria-label="SmartCar" scrollable hide-header hide-footer>
        <div class="modal-header border-0"><button class="btn-close" type="button" block @click="$bvModal.hide('SmartCarModal')"></button></div>
        <div class="modal-body pb-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <!-- Portfolio Modal - Title-->
                        <h2 class="modal-title mb-0 text-center">Smart Car</h2>
                        <!-- Icon Divider-->
                        <DividerIcon/>
                        <!-- Portfolio Modal - Image-->
                        <img class="img-fluid rounded mb-5" src="@/assets/SmartCar.png" alt="Smart Car Race Loge" />
                        <!-- Portfolio Modal - Text-->
                        <h3>Introduction</h3>
                        <p>This project was for the 13th <a href="https://smartcar.cdstm.cn/index">NXP Cap National University Students Intelligent Car Race</a> . Our team, on behalf of Chongqing University, won the First Prize in the Signal-Targeted group. I was responsible for algorithm design and coding, with my two teammates doing mechanical part and circuit boards respectively.</p>
                        <hr>

                        <h3>Details</h3>
                        <p>I named our car for the final national-wide contest MarkFour and it consists of the following:</p>
                        <ul>
                            <li><b>Three cameras</b>: Main camera for locating signal. Obstacle-avoiding camera for detecting obstacle. Side-view camera for finding signals that are of the vision of the main camera.</li>
                            <li><b>Two NXP Kinetis K60 MCUs</b>: Analysing singals from cameras and encoders. Making decisions and controlling motors.</li>
                            <li><b>Two motors and their driver board</b>: Providing traction and braking power.</li>
                            <li><b>Two photoelectric encoders</b>: Measuring the speed of two rare wheels.</li>
                            <li><b>Servo motor</b>: Controling the angle for front wheels.</li>
                            <li><b>Motherboard</b>: Connecting and providing support for electric components.</li>
                            <li><b>Buttons/LED Screen/Bluetooth</b>: Enabling operators to change parameters and the car to sending back data in real time.</li>
                            <li><b>Battery</b>: Connected to motor driver board and mother board.</li>
                        </ul>
                        <p align="center"><img class="features-img" src="@/assets/PortfolioModals/SmartCarPortrait.png" alt="Smart Car Portrait"/></p>
                        <p>The project is writen in C and built with IAR. Codes in folder <b>20180818MarkFourSlave</b> and <b>20180818MarkFourMaster</b> are the final version, one for each MCU.</p>
                        <hr>

                        <h3>Performance</h3>
                        <p>The following video clip shows MarkFour's performance. The positions for signal lights on the ground are randomly chosen. The car needs to locate where the new signal is and run to it as fast as possible, avoidng potential collisions with objects. The following process took MarkFour 11.9s.</p>
                        <p align="center"><img class="features-img" src="@/assets/PortfolioModals/SmartCarTest.gif" alt="Smart Car Portrait"/></p>
                        <hr>

                        <h3>Repository</h3>
                        <p class="mb-4">
                            <a class="text-black" href="https://github.com/LiGaCu/SmartCar_K60DN512" target="_blank" rel="noopener noreferrer">
                                <b-icon icon="github" font-scale="2"/>
                            </a>
                        </p>
                        <!--Footer Close Botton-->
                        <div class="text-center">
                            <button class="btn btn-footer" href="#!" block @click="$bvModal.hide('SmartCarModal')">
                                <b-icon icon="x"></b-icon>
                                Close Window
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import DividerIcon from '../DividerIcon.vue';

export default {
  name: 'SmartCarModal',
  components: {
      DividerIcon
  }
}
</script>

<style scoped>
.btn-close {
    color: #1abc9c;
    font-size: 2rem;
    padding: 1rem;
}
.btn-footer {
    border-color: #1abc9c;
    background: #1abc9c;
    border-radius: 0.5rem;
    color: #fff;
}
.modal-title {
  font-size: 2.25rem;
  line-height: 2rem;
  color: #2c3e50;
}
.features-img {
    width: 90%;
}
@media (min-width: 992px) {
  .modal-title {
    font-size: 3rem;
    line-height: 2.5rem;
  }
}
</style>