<template>
     <section class="page-section text-white mb-0" id="experience">
        <div class="container">
            <!-- Experience Section Heading-->
            <h2 class="page-section-heading text-center text-uppercase text-white">Experience</h2>
            <!-- Icon Divider-->
            <DividerIcon class="divider-light"/>
            <!-- Experience Section Content-->
            <div class="row">
                <ul class="timeline">
                    <li>
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="@/assets/AWS_Logo.jpg" alt="AWS Logo" /></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>Jan 2022 - Present</h4>
                                <h4 class="subheading">Software Development Engineer at AWS</h4>
                            </div>
                            <div class="timeline-body"><p>Learning and building cloud computing with AWS.<br> Amazon Web Services (AWS) is the world’s most comprehensive and broadly adopted cloud platform, offering over 200 fully featured services from data centers globally. Millions of customers—including the fastest-growing startups, largest enterprises, and leading government agencies—are using AWS to lower costs, become more agile, and innovate faster.</p></div>
                        </div>
                    </li>
                    <li class="timeline-inverted">
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="@/assets/AWS_Cloud_Logo.png" alt="AWS Cloud Logo" /></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>June 2021 - Aug 2021</h4>
                                <h4 class="subheading">Software Dev Engineer Intern at AWS</h4>
                            </div>
                            <div class="timeline-body">
                                <p> Worked within AWS Hyperplane team under AWS EC2 Networking in Seattle, United States;<br>
                                    Designed and built an EC2 instance retirement processing service, created and managed cloud infrastructures with AWS CDK for TypeScript, defined service behaviors on AWS Lambda with Java;<br>
                                    Modified the team’s network monitoring service, extended instance lifecycle control components to client groups, onboarded the intern project onto the team’s service in production environment.
                                </p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="@/assets/USC_Logo.png" alt="USC Logo" /></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>Jan 2020 - Dec 2021</h4>
                                <h4 class="subheading">University of Southern California</h4>
                            </div>
                            <div class="timeline-body">
                                <p> Master of Science in Electrical Engineering | GPA: 3.9/4.0<br>
                                    Courses: Computer Networks, Analysis of Algorithms, Web Technologies, Applied and Cloud Computing, Computing Principles, Parallel and Distributed Computation
                                </p>
                            </div>
                        </div>
                    </li>
                    <li class="timeline-inverted">
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="@/assets/Texas_A&M_University_Logo.png" alt="Texas A&M Logo" /></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>Aug 2019 - Jan 2020</h4>
                                <h4 class="subheading">Texas A&amp;M University</h4>
                            </div>
                            <div class="timeline-body">
                                <p> Master of Science in Computer Engineering before transferring out | GPA: 4.0/4.0<br>
                                    Courses: Machine Learning, Data Mining &amp; Analysis, Digital Image Processing
                                </p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="@/assets/UBC_Logo.png" alt="UBC Logo" /></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>Summer 2017</h4>
                                <h4 class="subheading">University of British Columbia</h4>
                            </div>
                            <div class="timeline-body">
                                <p> Study abroad in Vancouver, Canada<br>
                                    Courses and grades: Communication System 92%; Introduction to Digital Systems Design with FPGAs 88%
                                </p>
                            </div>
                        </div>
                    </li>
                    <li class="timeline-inverted">
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="@/assets/Chongqing_University_Logo.png" alt="Chongqing University Logo" /></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>Aug 2015 - July 2019</h4>
                                <h4 class="subheading">Chongqing University</h4>
                            </div>
                            <div class="timeline-body">
                                <p> Bachelor of Engineering in Measurement &amp; Control Technology and Instruments<br>
                                    GPA: 3.5/4.0 | Ranking in specialty: 8/105
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- Experience Section Button-->
            <div class="text-center mt-4">
                <a class="btn btn-xl btn-outline-light" href="https://www.linkedin.com/in/jiatong-li" target="_blank" rel="noopener noreferrer">
                    <b-icon icon="linkedin"></b-icon>&nbsp;&nbsp;See More!
                </a>
            </div>
        </div>
    </section>
</template>

<script>
import DividerIcon from './DividerIcon.vue';

export default {
  name: 'Experience',
  components: {
      DividerIcon
  }
}
</script>

<style scoped>
#experience {
    background-color: #1abc9c;
}
.btn-xl {
    padding: 1rem 1.75rem;
    font-size: 1.25rem;
    border-radius: 0.5rem;
    border-width: 0.125rem;
}

.timeline {
  position: relative;
  padding: 0;
  list-style: none;
}
.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  width: 2px;
  margin-left: -1.5px;
  content: "";
  background-color: #e9ecef;
}
.timeline > li {
  position: relative;
  min-height: 50px;
  margin-bottom: 50px;
}
.timeline > li:after, .timeline > li:before {
  display: table;
  content: " ";
}
.timeline > li:after {
  clear: both;
}
.timeline > li .timeline-panel {
  position: relative;
  float: right;
  width: 100%;
  padding: 0 20px 0 100px;
  text-align: left;
}
.timeline > li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}
.timeline > li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}
.timeline > li .timeline-image {
  position: absolute;
  z-index: 100;
  left: 0;
  width: 80px;
  height: 80px;
  margin-left: 0;
  text-align: center;
  color: white;
  border: 7px solid #e9ecef;
  border-radius: 100%;
  background-color: #ffffff;
}
.timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
  font-size: 10px;
  line-height: 14px;
  margin-top: 12px;
}
.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}
.timeline > li:last-child {
  margin-bottom: 0;
}
.timeline .timeline-heading h4, .timeline .timeline-heading .h4 {
  margin-top: 0;
  color: inherit;
}
.timeline .timeline-heading h4.subheading, .timeline .timeline-heading .subheading.h4 {
  text-transform: none;
}
.timeline .timeline-body > ul,
.timeline .timeline-body > p {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .timeline:before {
    left: 50%;
  }
  .timeline > li {
    min-height: 100px;
    margin-bottom: 100px;
  }
  .timeline > li .timeline-panel {
    float: left;
    width: 41%;
    padding: 0 20px 20px 30px;
    text-align: right;
  }
  .timeline > li .timeline-image {
    left: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
  }
  .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
    font-size: 13px;
    line-height: 18px;
    margin-top: 16px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    padding: 0 30px 20px 20px;
    text-align: left;
  }
}
@media (min-width: 992px) {
  .timeline > li {
    min-height: 150px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px;
  }
  .timeline > li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }
  .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
    font-size: 18px;
    line-height: 26px;
    margin-top: 30px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 20px 20px;
  }
}
@media (min-width: 1200px) {
  .timeline > li {
    min-height: 170px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px 100px;
  }
  .timeline > li .timeline-image {
    width: 170px;
    height: 170px;
    margin-left: -85px;
  }
  .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
    margin-top: 40px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 100px 20px 20px;
  }
}
</style>