<template>
    <b-modal id="TrojanMapModal" size="xl" aria-label="TrojanMap" scrollable hide-header hide-footer>
        <div class="modal-header border-0"><button class="btn-close" type="button" block @click="$bvModal.hide('TrojanMapModal')"></button></div>
        <div class="modal-body pb-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <!-- Portfolio Modal - Title-->
                        <h2 class="modal-title mb-0 text-center">Trojan Map</h2>
                        <!-- Icon Divider-->
                        <DividerIcon/>
                        <!-- Portfolio Modal - Image-->
                        <img class="img-fluid rounded mb-5" src="@/assets/PortfolioModals/TrojanMap/Cover.png" alt="Graph Algorithms" />
                        <!-- Portfolio Modal - Text-->
                        <h3>Introduction</h3>
                        <p>This project focuses on using data structures in C++ and implementing various graph algorithms to build a map application. The full demonstration video of this project can be seen on <a href="https://youtu.be/pPjPZVROoVY">Youtube</a>.</p>
                        <p align="center"><img class="features-img" src="@/assets/PortfolioModals/TrojanMap/Introduction.gif" alt="TrojanMap UI"/></p>
                        
                        <h3>Prerequisites</h3>
                        The following libraries are used in this project (Instructions for installing them can be found in the REAMME.md file in the GitHub <a href="#TrojanMapRepo">repository</a>):
                        <ul>
                            <li>OpenCV</li>
                            <li>Brazel</li>
                            <li>Ncurses</li>
                        </ul>

                        <h3>Run the program</h3>
                        <p>After the complilation, please run:</p>
<pre><code class="lang-shell">$ bazel run src/main:main</code></pre>
                        <p>If everything is correct, this menu will show up.</p>
<pre><code class="lang-shell">Torjan Map
**************************************************************
* Select the function you want to execute.
* 1. Autocomplete
* 2. Find the position
* 3. CalculateShortestPath
* 4. Travelling salesman problem
* 5. Cycle Detection
* 6. Topological Sort
* 7. Exit
**************************************************************
Please select 1 - 7:
</code></pre>
                        <h3>Test the program</h3>
                        <p>trojanmap_test.cc is for you to test the fuctionality of algorithms in this project (you can add more   ), please run</p>
<pre><code class="lang-shell">$ bazel test tests:trojanmap_test</code></pre>
                        <hr>
                        
                        <h3>Feature 1: Autocomplete the location name</h3>
                        <h4>Description</h4>
                        <p>We consider the names of nodes as the locations. User types in the partial name of the location and the program shows a list of possible locations with partial name as prefix on the terminal. This function treat uppercase and lower case as the same character.</p>
                        <p>Example:</p>
                        <p>Input: &quot;ch&quot; <br>
                        Output: [&quot;ChickfilA&quot;, &quot;Chipotle Mexican Grill&quot;]</p>
                        <p>Input: &quot;ta&quot; <br>
                        Output: [&quot;Target&quot;, &quot;Tap Two Blue&quot;]</p>
                        <p align="center"><img class="features-img" src="@/assets/PortfolioModals/TrojanMap/Feature1.png" alt="Feature1"/></p>
                        <h4>Time Complexity</h4>
                        <p>The amortized time complexity of this function is O(n), where n is the total number of nodes with a name. The first time user use this function, it will take O(n<em>) to initialize the hashmap, where n</em> is the total number of nodes in the map.</p>
                        <h4>Time spend in experiment</h4>
                        <p>On a 2015 Dell laptop with i7-5700U, it takes 96 microseconds to search for &quot;Us&quot;.</p>
                        
                        <h3>Feature 2: Find the place&#39;s Coordinates in the Map</h3>
                        <h4>Description</h4>
                        <p>User types in the a location&#39;s fullname, the program returns the latitude and longitude on the terminal and mark the given locations on the popup map window. There are no duplicated location names stored in the map. If the location does not exist, it will shows no result match on the terminal.</p>
                        <p align="center"><img class="features-img" src="@/assets/PortfolioModals/TrojanMap/Feature2-0.png" alt="Feature2"/></p>
                        <p align="center"><img class="features-img" src="@/assets/PortfolioModals/TrojanMap/Feature2-1.png" alt="Feature2"/></p>
                        <h4>Time Complexity</h4>
                        <p>The amortized time complexity of this function is O(1). The first time user use this function, it will take O(n<em>) to initialize the hashmap, where n</em> is the total number of nodes in the map.</p>
                        <h4>Time spend in experiment</h4>
                        <p>On a 2015 Dell laptop with i7-5700U, it takes 21 microseconds to search for &quot;USC Village Gym&quot;.</p>

                        <h3>Feature 3: CalculateShortestPath between two places</h3>
                        <h4>Description</h4>
                        <p>User types in two locations A and B, the program returns the best route from A to B on the terminal and shows the path on the popup map window. The distance between 2 points is the euclidean distance using latitude and longitude. User can choose to use Dijkstra algorithm or Bellman-Ford algorithm. If there is no path, please return empty vector.</p>
                        <p align="center"><img class="features-img" src="@/assets/PortfolioModals/TrojanMap/Feature3-0.png" alt="Feature3"/></p>
                        <p align="center"><img class="features-img" src="@/assets/PortfolioModals/TrojanMap/Feature3-1.png" alt="Feature3"/></p>
                        <p>The path calculated by Dijkstra:</p>
                        <p align="center"><img class="features-img" src="@/assets/PortfolioModals/TrojanMap/Feature3-2.png" alt="Feature3"/></p>
                        The path calculated by Bellman-Ford is the same.
                        <h4>Time Complexity</h4>
                        <ul>
                            <li>For Dijkstra: The function use minheap to implement Dijkstra algorithm. The time complexity of this function is O(mlogn), where m is the total number of edges in the map and n is the total number of nodes.</li>
                            <li>For Bellman-Ford: The time complexity of this function is O(mn), where m is the total number of edges in the map and n is the total number of nodes.</li>
                        </ul>
                        <h4>Time spend in experiment</h4>
                        <p>On a 2015 Dell laptop with i7-5700U:</p>
                        <ul>
                            <li>Dijkstra takes 11579 microseconds to search for path from &quot;Department of Motor Vehicles&quot; to &quot;JeffersonUSC&quot;.</li>
                            <li>Bellman-Ford takes 713011 microseconds to search for path from &quot;Department of Motor Vehicles&quot; to &quot;JeffersonUSC&quot;.</li>
                        </ul>

                        <h3>Feature 4: The Travelling Trojan Problem (AKA Traveling Salesman!)</h3>
                        <h4>Description</h4>
                        <p>We assume that a complete graph is given to you for this part. That means each node is a neighbor of all other nodes.
                        Given a vector of location ids, assume every location can reach all other locations in the vector (i.e. assume that the vector of location ids is a complete graph).
                        User type the number of nodes N to randomly generate to build the fully connected map. This function find the shortest route that covers all the locations exactly once and goes back to the start point. The program will show the routes on the popup map. </p>
                        <p>User can choose to use the following algorithms:</p>
                        <ul>
                            <li>Brute Force Method</li>
                            <li>2-Opt</li>
                            <li>3-Opt</li>
                        </ul>
                        <p align="center"><img class="features-img" src="@/assets/PortfolioModals/TrojanMap/Feature4-0.png" alt="Feature4"/></p>
                        <p>For each intermediate solution, it will create a new plot and shows that as a animation and save it in src/lib/output001.avi.</p>
                        <p align="center"><img class="features-img" src="@/assets/PortfolioModals/TrojanMap/Feature4-1.gif" alt="Feature4"/></p>
                        <h4>Algorithms Comparation</h4>
                        <p>On a 2015 Dell laptop with i7-5700U:</p>
                        <ul>
                            <li>Brute-Force takes 8542497 microseconds to search for a path of 4.58363 miles length connecting random chosen 12 nodes.
                                <p align="center"><img class="features-img" src="@/assets/PortfolioModals/TrojanMap/Feature4-2.png" alt="Feature4"/></p>
                            </li>
                            <li>2-Opt method takes 19713 microseconds to search for a path of 4.68363 miles length connecting the same 12 nodes as above.
                                <p align="center"><img class="features-img" src="@/assets/PortfolioModals/TrojanMap/Feature4-3.png" alt="Feature4"/></p>
                            </li>
                            <li>3-Opt method takes 31974 microseconds to search for a path of 4.95979 miles length connecting the same 12 nodes as above.
                                <p align="center"><img class="features-img" src="@/assets/PortfolioModals/TrojanMap/Feature4-4.png" alt="Feature4"/></p>
                            </li>
                        </ul>

                        <h3>Feature 5: Cycle Detection</h3>
                        <h4>Description</h4>
                        <p>User types in four geographical parameters to form a square-shaped region in the map, then the program detect if there is a cycle in the given region or not and shows the result in the terminal. </p>
                        <p align="center"><img class="features-img" src="@/assets/PortfolioModals/TrojanMap/Feature5-0.png" alt="Feature5"/></p>
                        <p align="center"><img class="features-img" src="@/assets/PortfolioModals/TrojanMap/Feature5-1.png" alt="Feature5"/></p>
                        <h4>Time Complexity</h4>
                        <p>The time complexity of this function is O(m+n), where m is the total number of edges in the given region and n is the total number of nodes in the map.</p>
                        <h4>Time spend in experiment</h4>
                        <p>On a 2015 Dell laptop with i7-5700U:</p>
                        <p>The function takes 917 microseconds to search for the region {-118.290919, -118.282911, 34.02235, 34.019675}.</p>
                        
                        <h3>Feature 6: Topological Sort</h3>
                        <h4>Description</h4>
                        <p>Tommy Trojan got a part-time job from TrojanEats, for which he needs to pick up and deliver food from local restaurants to various location near the campus. Tommy needs to visit a few different location near the campus with certain order, since there are some constraints. For example, he must first get the food from the restaurant before arriving at the delivery point. </p>
                        <p>The TrojanEats app will have some instructions about these constraints. So, Tommy asks you to help him figure out the feasible route!</p>
                        <p>User can specify a file containing location names that Tommy needs to visit, and also a file containing some dependencies between those locations.</p>
                        <p>For example, in the input files:</p>
                        <p align="center"><img class="features-img" src="@/assets/PortfolioModals/TrojanMap/Feature6-0.png" alt="Feature6"/></p>
                        <p>And the program output:</p>
                        <p align="center"><img class="features-img" src="@/assets/PortfolioModals/TrojanMap/Feature6-1.png" alt="Feature6"/></p>
                        <p>This program can visualize the results on the map. It will plot each location name and also some arrowed lines to demonstrate a feasible route.</p>
                        <p align="center"><img class="features-img" src="@/assets/PortfolioModals/TrojanMap/Feature6-2.png" alt="Feature6"/></p>
                        <h4>Time Complexity</h4>
                        <p>The time complexity of this function is O(m+n), where m is the total number of edges in the given files and n is the total number of nodes in the given files.</p>
                        <h4>Time spend in experiment</h4>
                        <p>On a 2015 Dell laptop with i7-5700U:</p>
                        <p>The function takes 163 microseconds to search for the input files as shown above.</p>
                        <hr>

                        <h3 id="TrojanMapRepo">Repository</h3>
                        <p><a class="text-black" href="https://github.com/LiGaCu/TrojanMap" target="_blank" rel="noopener noreferrer">
                                <b-icon icon="github" font-scale="2"/>
                            </a></p>
                        <!--Footer Close Botton-->
                        <div class="text-center mt-4">
                            <button class="btn btn-footer" href="#!" block @click="$bvModal.hide('TrojanMapModal')">
                                <b-icon icon="x"></b-icon>
                                Close Window
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import DividerIcon from '../DividerIcon.vue';

export default {
  name: 'TrojanMapModal',
  components: {
      DividerIcon
  }
}
</script>

<style scoped>
.btn-close {
    color: #1abc9c;
    font-size: 2rem;
    padding: 1rem;
}
.btn-footer {
    border-color: #1abc9c;
    background: #1abc9c;
    border-radius: 0.5rem;
    color: #fff;
}
.modal-title {
  font-size: 2.25rem;
  line-height: 2rem;
  color: #2c3e50;
}
.features-img {
    width: 90%;
}
@media (min-width: 992px) {
  .modal-title {
    font-size: 3rem;
    line-height: 2.5rem;
  }
  .features-img {
    width: 75%;
  }
}
</style>