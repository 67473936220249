<template>
    <section class="page-section portfolio" id="portfolio">
        <div class="container">
            <!-- Portfolio Section Heading-->
            <h2 class="page-section-heading text-center text-uppercase mb-0">Portfolio</h2>
            <!-- Icon Divider-->
            <DividerIcon/>
            <!-- Portfolio Grid Items-->
             <div class="row justify-content-center">
                <!-- Portfolio Item-->
                <div class="col-md-6 col-lg-4 mb-5" v-for="project in projects" :key="project.name">
                    <!--Toggle modal visibility-->
                    <div class="portfolio-item mx-auto" v-b-modal="project.componentName">
                        <div class="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div class="portfolio-item-caption-content text-center text-white"><b-icon icon="arrows-angle-expand" font-scale="3"></b-icon></div>
                        </div>
                        <img class="img-fluid" :src="project.modalImage" :alt="project.name" />
                    </div>
                    <!-- Component changes when project.componentName changes -->
                    <component v-bind:is="project.componentName"></component>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import DividerIcon from './DividerIcon.vue';
import TrojanFilmsModal from './PortfolioModals/TrojanFilmsModal.vue';
import TrojanMapModal from './PortfolioModals/TrojanMapModal.vue';
import VisualRobotModal from './PortfolioModals/VisualRobotModal.vue';
import SmartCarModal from './PortfolioModals/SmartCarModal.vue';
import MusicPlatformModal from './PortfolioModals/MusicPlatformModal.vue';
import MoreProjectsModal from './PortfolioModals/MoreProjects.vue';

export default {
  name: 'Portfolio',
  components: {
      DividerIcon,
      TrojanFilmsModal,
      TrojanMapModal,
      VisualRobotModal,
      SmartCarModal,
      MusicPlatformModal,
      MoreProjectsModal
  },
  data: function() {
      return {
          projects: [
              {
                  name: "TrojanFilms",
                  componentName: "TrojanFilmsModal",
                  modalImage: require("@/assets/TrojanFilms.png")
              },
              {
                  name: "TrojanMap",
                  componentName: "TrojanMapModal",
                  modalImage: require("@/assets/TrojanMap.png")
              },
              {
                  name: "VisualGuidanceRobot",
                  componentName: "VisualRobotModal",
                  modalImage: require("@/assets/VisualGuidanceRobot.png")
              },
              {
                  name: "SmartCar",
                  componentName: "SmartCarModal",
                  modalImage: require("@/assets/SmartCar.png")
              },
              {
                  name: "MusicPlatform",
                  componentName: "MusicPlatformModal",
                  modalImage: require("@/assets/MusicPlatform.png")
              },
              {
                  name: "MoreProjects",
                  componentName: "MoreProjectsModal",
                  modalImage: require("@/assets/MoreProjects.png")
              },
          ]
      }
  }
}
</script>

<style scoped>
.portfolio h2{
    color: #2c3e50;
}
.portfolio .portfolio-item {
  cursor: pointer;
  position: relative;
  display: block;
  max-width: 25rem;
  border-radius: 0.5rem;
  overflow: hidden;
}
.portfolio .portfolio-item .portfolio-item-caption {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  background-color: rgba(26, 188, 156, 0.9);
}
.portfolio .portfolio-item .portfolio-item-caption:hover {
  opacity: 1;
}
.portfolio .portfolio-item .portfolio-item-caption .portfolio-item-caption-content {
  font-size: 1.5rem;
}
</style>