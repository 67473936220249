<template>
     <header class="masthead text-white text-center">
        <div class="container d-flex align-items-center flex-column">
            <!-- Masthead Avatar Image-->
            <img class="masthead-avatar mb-5" src="@/assets/JiatongAvatar.png" alt="Jiatong Li Photo" />
            <!-- Masthead Heading-->
            <h1 class="masthead-heading text-uppercase mb-0">Jiatong Li</h1>
            <!-- Icon Divider-->
            <DividerIcon class="divider-light"/>
            <!-- Masthead Subheading-->
            <p class="masthead-subheading font-weight-light mb-0">Software Development Engineer - USC Alum</p>
        </div>
    </header>
</template>

<script>
import DividerIcon from './DividerIcon.vue';

export default {
  name: 'Masthead',
  components: {
      DividerIcon
  }
}
</script>

<style scoped>
.masthead {
  padding-top: calc(6rem + 74px);
  padding-bottom: 6rem;
  background-color: #1abc9c;
}
.masthead .masthead-heading {
  font-size: 2.75rem;
  line-height: 2.75rem;
}
.masthead .masthead-subheading {
  font-size: 1.25rem;
}
.masthead .masthead-avatar {
  width: 15rem;
}

@media (min-width: 992px) {
  .masthead {
    padding-top: calc(6rem + 104px);
    padding-bottom: 6rem;
  }
  .masthead .masthead-heading {
    font-size: 4rem;
    line-height: 3.5rem;
  }
  .masthead .masthead-subheading {
    font-size: 1.5rem;
  }
}
</style>