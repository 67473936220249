<template>
  <div id="app">
    <!--<img alt="Vue logo" src="./assets/logo.png">-->
    <!--<HelloWorld msg="Welcome to Your Vue.js App"/>-->
     <Navbar/>
     <Masthead/>
     <Portfolio/>
     <Experience/>
     <About/>
     <MyFooter/>
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import Navbar from './components/Navbar.vue'
import Masthead from './components/Masthead.vue'
import Portfolio from './components/Portfolio.vue'
import Experience from './components/Experience.vue'
import MyFooter from './components/MyFooter.vue'
import About from './components/About.vue'

export default {
  name: 'App',
  components: {
    //HelloWorld,
    Navbar,
    Masthead,
    Portfolio,
    Experience,
    About,
    MyFooter
  },
  mounted () {
    window.addEventListener('load', async () => {
      fetch('https://server.lijiatong1997.com/personalpage/count')
      .then(response => response.json())
      .then(result => {
        console.log("Success: ", result)
      })
      .catch(error => {
        console.error('Error:', error);
      });
    })
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
@import url('https://fonts.googleapis.com/css?family=Lato:400,700,400italic,700italic');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');

html {
  height: 100%;
  scroll-padding-top: calc(4.5rem - 1px);
}

body {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-weight: 700 !important;
}

/* Styling for page sections*/
.page-section {
    padding: 6rem 0;
}
.page-section .page-section-heading {
  font-size: 2.25rem;
  line-height: 2rem;
}
@media (min-width: 992px) {
  .page-section .page-section-heading{
    font-size: 3rem;
    line-height: 2.5rem;
  }
}
</style>