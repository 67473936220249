<template>
    <section class="page-section" id="about">
        <div class="container">
            <!-- Contact Section Heading-->
            <h2 class="page-section-heading text-center text-uppercase mb-0">About</h2>
            <!-- Icon Divider-->
            <DividerIcon/>
            <div class="row justify-content-center">
                <div class="col-12 col-lg-10 mb-3 px-3 about-content">
                    <p>I am a software development engineer working at AWS, Seattle. Before I joined AWS, I was an Electrical Engineering major student at University of Southern California, Los Angeles (Fight On, Trojans!). Learning/inventing new technologies and building interesting projects are the things make me excited: it is us engineers' mission to make the world a better place!</p>
                    <p>My undergraduate study was finished at Chongqing University, China and related to robotics. I finished my first semester for master degree in computer engineering program at Texas A&amp;M. My skills (programming languages &amp; tools) include Java, C++, Python, JavaScript, AWS CDK, Vue, Angular, Bootstrap, Express.js, OpenCV, MATLAB, SolidWorks ...</p>
                    <p>Apart from being a developer, I enjoy travelling and experiencing different cultures. The following map shows some of the places I have been to: <span style="color:#007dbc;">blue pins</span> for the places that I've lived for over a month, <span style="color:#ffa724">orange pins</span> for the short-term travelling places (Click on them to see details). When staying indoor, I would like to watch movies and television shows and make-my friends all love-chinese food.</p>
                </div>
                <div class="col-12 col-lg-10 m-0 p-0 world_map_wrapper">
                    <img class="img-fluid m-0 p-0" @load="initComputedPins" src="@/assets/World_Map.svg" ref="world_map"/>
                    <b-button class="world_map_pin m-0 p-0" v-for="pin in computedPins" :key="pin.name" :style="{'left':pin.offsetX+'px','top':pin.offsetY+'px'}" v-b-tooltip.focus.v-info="pin.name" tabindex="0" :class="{ world_map_pin_lived : pin.lived }"></b-button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import DividerIcon from './DividerIcon.vue';

export default {
    name: 'About',
    components: {
        DividerIcon
    },
    data: function(){
        return {
            computedPins: [],
            pins: [
                {
                    latitude: 41.8057,
                    longitude: 123.4315,
                    name: "Shenyang",
                    lived: true
                },
                {
                    latitude: 34.0522,
                    longitude: -118.2437,
                    name: "Los Angeles",
                    lived: true
                },
                {
                    latitude: 30.6280,
                    longitude: -96.3344,
                    name: "College Station",
                    lived: true
                },
                {
                    latitude: 29.4316,
                    longitude: 106.9123,
                    name: "Chongqing",
                    lived: true
                },
                {
                    latitude: 45.5152,
                    longitude: -122.6784,
                    name: "Portland",
                    lived: true
                },
                {
                    latitude: 47.6062,
                    longitude: -122.3321,
                    name: "Seattle",
                    lived: true
                },
                {
                    latitude: 49.2827,
                    longitude: -123.1207,
                    name: "Vancouver",
                    lived: true
                },
                {
                    latitude: 30.5728,
                    longitude: 104.0668,
                    name: "Chengdu"
                },
                {
                    latitude: 39.9042,
                    longitude: 116.4074,
                    name: "Beijing",
                    lived: true
                },
                {
                    latitude: 22.3193,
                    longitude: 114.1694,
                    name: "Hong Kong"
                },
                {
                    latitude: 24.4798,
                    longitude: 118.0894,
                    name: "Xiamen"
                },
                {
                    latitude: 36.0671,
                    longitude: 120.3826,
                    name: "Qingdao"
                },
                {
                    latitude: 26.8550,
                    longitude: 100.2277,
                    name: "Lijiang"
                },
                {
                    latitude: 18.2528,
                    longitude: 109.5119,
                    name: "Sanya"
                },
                {
                    latitude: 1.3521,
                    longitude: 103.8198,
                    name: "Singapore"
                },
                {
                    latitude: 37.7749,
                    longitude: -122.4194,
                    name: "San Francisco"
                },
                {
                    latitude: 40.7128,
                    longitude: -74.0060,
                    name: "New York City"
                },
                {
                    latitude: 48.8566,
                    longitude: 2.3522,
                    name: "Paris"
                },
                {
                    latitude: 48.1351,
                    longitude: 11.5820,
                    name: "Munich"
                },
                {
                    latitude: 41.9028,
                    longitude: 12.4964,
                    name: "Rome"
                },
                {
                    latitude: 46.6863,
                    longitude: 7.8632,
                    name: "Interlaken"
                },
                {
                    latitude: 64.8378,
                    longitude: -147.7164,
                    name: "Fairbanks"
                },
                {
                    latitude: -33.8688,
                    longitude: 151.2093,
                    name: "Sydney"
                },
                {
                    latitude: -36.8509,
                    longitude: 174.7645,
                    name: "Auckland"
                }
            ],
            mapOffset: {
                y: 0.5,
                x: -10.25
            }
        }
    },
    methods: {
        transPinsCoordinates () {
            let mapWidth = this.$refs.world_map.clientWidth;
            let mapHeight = this.$refs.world_map.clientHeight;
            let earthRadius = (mapWidth/2.666269758)/2;
            let AA = [0.8487,0.84751182,0.84479598,0.840213,0.83359314,0.8257851,0.814752,0.80006949,0.78216192,0.76060494,0.73658673,0.7086645,0.67777182,0.64475739,0.60987582,0.57134484,0.52729731,0.48562614,0.45167814];
            let BB = [0,0.0838426,0.1676852,0.2515278,0.3353704,0.419213,0.5030556,0.5868982,0.67182264,0.75336633,0.83518048,0.91537187,0.99339958,1.06872269,1.14066505,1.20841528,1.27035062,1.31998003,1.3523];
            this.computedPins = this.computedPins.map( pin => {
                let trueLong = pin.longitude + this.mapOffset.x;
                let trueLati = pin.latitude + this.mapOffset.y;
                trueLong = trueLong < -180 ? trueLong + 360 : trueLong;
                trueLong = trueLong > 180 ? trueLong - 360 : trueLong;
                trueLati = trueLati < -90 ? trueLati + 180 : trueLati;
                trueLati = trueLati > 90 ? trueLati - 180 : trueLati;
                let longSign = trueLong < 0 ? -1 : 1;
                let latiSign = trueLati < 0 ? -1 : 1;
                let longAbs = Math.abs(trueLong);
                let latiAbs = Math.abs(trueLati);
                let radian = 0.017453293;// pi/180
                let low = Math.floor((latiAbs-0.0000000001)/5)*5;
                low = (latiAbs == 0) ? 0 : low; 
                let high = low + 5;

                let lowIndex = low/5;
                let highIndex = high/5;
                let ratio = (latiAbs-low)/5;

                var adjAA = ((AA[highIndex]-AA[lowIndex])*ratio)+AA[lowIndex];
                var adjBB = ((BB[highIndex]-BB[lowIndex])*ratio)+BB[lowIndex];

                let x = adjAA * longAbs * radian * longSign * earthRadius;
                let y = adjBB * latiSign * earthRadius;

                x = x + (mapWidth/2);
                y = (mapHeight/2) - y;

                return {...pin, offsetY:y, offsetX:x};
            });
        },
        initComputedPins () {
            this.computedPins = this.pins.map( pin => {
                return {...pin, lived: pin.lived || false}
            }).sort( (a, b) => { 
                if ( a.lived && b.lived || !a.lived && !b.lived){
                    return b.latitude - a.latitude;
                }
                return a.lived ? -1 : 1;
            });
            this.transPinsCoordinates();
        }
    },
    created() {
        window.addEventListener("resize", this.transPinsCoordinates);
    },
    destroyed() {
        window.removeEventListener("resize", this.transPinsCoordinates);
    }
}
</script>

<style scoped>
#about h2{
    color: #2c3e50;
}
#about .about-content {
    color:#6c757d;
}
.world_map_wrapper {
    position: relative;
}
.world_map_pin {
    width: 5px;
    height: 5px;
    border: 1px solid #ffa724;
    background-color: transparent;
    border-radius: 50%;
    transition: box-shadow .2s ease-out;

    position: absolute;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.world_map_pin_lived {
    border: 1px solid #007dbc;
    background-color: transparent;
}
button:hover.world_map_pin {
    border-color: #ffa724;
    box-shadow: 0 0 0 2px #eb6617, 0 0 0 4px #ffa724;
    background-color: transparent;
}
button:hover.world_map_pin_lived {
    border-color: #007dbc;
    box-shadow: 0 0 0 2px #009cea, 0 0 0 4px #007dbc;
    background-color: transparent;
}
button:focus.world_map_pin {
    border-color: #ffa724;
    box-shadow: 0 0 0 2px rgba(235, 102, 23, 0.5);
    background-color: #ffa724;
}
button:focus.world_map_pin_lived {
    border-color: #007dbc;
    box-shadow: 0 0 0 2px rgba(0,157,251,0.5);
    background-color: #007dbc;
}

@media (min-width: 992px) {
    #about .about-content {
        font-size: 1.25rem;
        font-weight: 300;
    }
    .world_map_pin {
        border: 2px solid #ffa724;
        background-color: transparent;
        width: 9px;
        height: 9px;
    }
    .world_map_pin_lived {
       border: 2px solid #007dbc;
    }
}
</style>