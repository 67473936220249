<template>
    <!-- Icon Divider-->
    <div class="divider-custom">
        <div class="divider-custom-line"></div>
        <div class="divider-custom-icon"><b-icon icon="star-fill"></b-icon></div>
        <div class="divider-custom-line"></div>
    </div>
</template>

<script>
export default {
  name: 'DividerIcon'
}
</script>

<style scoped>
.divider-custom {
  margin: 1.25rem 0 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.divider-custom .divider-custom-line {
  width: 100%;
  max-width: 7rem;
  height: 0.25rem;
  background-color: #2c3e50;
  border-radius: 1rem;
  border-color: #2c3e50;
}
.divider-custom .divider-custom-line:first-child {
  margin-right: 1rem;
}
.divider-custom .divider-custom-line:last-child {
  margin-left: 1rem;
}
.divider-custom .divider-custom-icon {
  color: #2c3e50;
  font-size: 2rem;
}
.divider-custom.divider-light .divider-custom-line {
  background-color: #fff;
}
.divider-custom.divider-light .divider-custom-icon {
  color: #fff;
}
</style>